import React from 'react';
import { st, classes } from './TimezoneIndication.st.css';
import SectionText from '../SectionText/SectionText';
import { TextButton } from 'wix-ui-tpa/TextButton';
import { TimezoneType } from '@wix/bookings-uou-types';
import { translate } from 'react-i18next';
import { InjectedTranslateProps } from 'yoshi-flow-editor-runtime';
import { useTPAComponentsConfig } from '../useTPAComponentsConfig';
import HiddenDropdown from '../HiddenDropdown/HiddenDropdown';
import { TimezoneDropdownOption } from '../../../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import { useRenderLocation } from '../useRenderLocation';

export type TimezoneIndicationProps = InjectedTranslateProps & {
  changeTimezoneCallback: (timezoneType: TimezoneType) => void;
  currentTimezoneType: TimezoneType;
  currentTimezone: string;
  timezoneDropdownOptions: TimezoneDropdownOption[];
};

const TimezoneIndicationComponent = ({
  t,
  changeTimezoneCallback,
  currentTimezoneType,
  currentTimezone,
  timezoneDropdownOptions,
}: TimezoneIndicationProps) => {
  const location = useRenderLocation();
  const {
    mobile: isMobile = false,
    rtl: isRTL = false,
  } = useTPAComponentsConfig();

  const changeTimezoneButton = () =>
    isMobile ? (
      <HiddenDropdown
        actionText={t('app.scheduling.timezone.change.mobile')}
        onDropdownChange={changeTimezoneCallback}
        selectedOption={currentTimezoneType}
        dropDownOptions={timezoneDropdownOptions!}
      />
    ) : (
      <TextButton
        className={st(classes.timezoneAction, { location })}
        data-hook={'desktop-timezone-toggle'}
        onClick={() =>
          changeTimezoneCallback(
            currentTimezoneType === TimezoneType.BUSINESS
              ? TimezoneType.CLIENT
              : TimezoneType.BUSINESS,
          )
        }
      >
        {currentTimezoneType === TimezoneType.BUSINESS
          ? t('app.scheduling.timezone.change-to-local')
          : t('app.scheduling.timezone.change-to-business')}
      </TextButton>
    );

  return (
    <div
      className={st(classes.timezoneIndicationContainer, { isRTL, isMobile })}
      data-hook={'timezone-indicator'}
    >
      <SectionText
        dataHook={'timezone-label'}
        className={classes.timezoneLabel}
        text={`${t('app.scheduling.timezone-label')} ${currentTimezone}`}
      />
      {changeTimezoneButton()}
    </div>
  );
};

export default translate()(TimezoneIndicationComponent);
