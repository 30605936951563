import React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import { st, classes } from './SessionDetails.st.css';
import { useSettings } from '@wix/tpa-settings/dist/src/contexts/SettingsContext/hooks';
import settingsParams from '../../BookingServicePage/settingsParams';
import { DailySession } from '../../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import { useTPAComponentsConfig } from '../../BookingServicePage/Widget/useTPAComponentsConfig';

export const SessionDetails: React.FC<DailySession> = ({
  startTime,
  durationText,
  durationAriaLabel,
  staffName,
}) => {
  const settings = useSettings();
  const {
    rtl: isRTL = false,
    mobile: isMobile = false,
  } = useTPAComponentsConfig();
  const staffVisibility = settings.get(settingsParams.scheduleStaffVisibility);
  const durationVisibility = settings.get(
    settingsParams.scheduleDurationVisibility,
  );
  const showDuration = () =>
    durationVisibility ? (
      <>
        <div
          dir={isRTL ? 'rtl' : undefined}
          className={classes.detailWrapper}
          aria-hidden
        >
          <Text className={classes.duration} data-hook="duration-text">
            {durationText}
          </Text>
        </div>
        <div className={classes.srOnly} data-hook="duration-aria-label">
          {durationAriaLabel}
        </div>
      </>
    ) : null;
  const showStaff = () =>
    staffVisibility && staffName ? (
      <div className={classes.detailWrapper}>
        <Text className={classes.staff} data-hook="staff-name">
          {staffName}
        </Text>
      </div>
    ) : null;
  return (
    <div className={st(classes.root, { isRTL, isMobile })}>
      <Text
        className={classes.startTime}
        data-hook="start-time"
        tagName={'time'}
      >
        {startTime}
      </Text>
      <div className={classes.details}>
        {showDuration()}
        {showStaff()}
      </div>
    </div>
  );
};
