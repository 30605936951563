import React from 'react';
import { translate, InjectedTranslateProps } from 'yoshi-flow-editor-runtime';
import { Button, PRIORITY, SIZE } from 'wix-ui-tpa/Button';
import { st, classes } from './BookButton.st.css';
import { useRenderLocation } from '../useRenderLocation';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';
import { useNavigation } from '../useNavigation';
import settingsParams from '../../settingsParams';
import { useTPAComponentsConfig } from '../useTPAComponentsConfig';
import { useVisitorBILogger } from '../../bi/biContext';

interface ControllerProps {
  dataHook?: string;
  className?: string;
  renderedAt?: string;
}

type BookButtonProps = InjectedTranslateProps & ControllerProps;

const BookButtonComponent: React.FC<BookButtonProps> = ({
  t,
  dataHook,
  className,
  renderedAt,
}) => {
  const location = useRenderLocation();
  const settings = useSettings();
  const { navigateToCalendar } = useNavigation();
  const { mobile: isMobile = false } = useTPAComponentsConfig();
  const biLogger = useVisitorBILogger();

  return (
    <Button
      className={st(classes.root, { location, isMobile }, className)}
      size={SIZE.medium}
      data-hook={dataHook || 'book-button'}
      priority={PRIORITY.primary}
      onClick={() => {
        biLogger.bookingsBookItClick({
          section: renderedAt,
        });
        navigateToCalendar(location);
      }}
      // fullWidth={isMobile}
      role="link"
      upgrade
    >
      {settings.get(settingsParams.bookButtonText) ||
        t('app.widget.cta.book-now')}
    </Button>
  );
};

export default translate()(BookButtonComponent);
