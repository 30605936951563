import React from 'react';
import { st, classes } from './Sidebar.st.css';
import { MainComponents, SectionTypes } from '../../types';
import { RenderLocationProvider } from '../useRenderLocation';
import settingsParams from '../../settingsParams';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';
import { translate, InjectedTranslateProps } from 'yoshi-flow-editor-runtime';
import getSidebarAriaLabel from './getSidebarAriaLabel';

type SidebarProps = InjectedTranslateProps & {
  className?: string;
  sectionType: SectionTypes;
};

const SidebarComponent: React.FC<SidebarProps> = ({
  t,
  className,
  sectionType,
  children,
}) => {
  const settings = useSettings();

  return (
    <RenderLocationProvider value={MainComponents.SIDEBAR}>
      <aside
        aria-label={getSidebarAriaLabel(t, sectionType)}
        data-hook="sidebar"
        className={st(
          classes.root,
          { sticky: settings.get(settingsParams.sidebarFreezePosition) },
          className,
        )}
      >
        {children}
      </aside>
    </RenderLocationProvider>
  );
};

export default translate()(SidebarComponent);
