import React from 'react';
import { st, classes } from './DailySessions.st.css';
import { DayDetails } from '../DayDetails/DayDetails';
import { SessionDetails } from '../SessionDetails/SessionDetails';
import Divider from '../Divider/Divider';
import { SchedulingDayViewModel } from '../../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import settingsParams from '../../BookingServicePage/settingsParams';
import { useLoadAllSessions } from '../useLoadAllSessions';
import { useTPAComponentsConfig } from '../../BookingServicePage/Widget/useTPAComponentsConfig';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';

export interface DailySessionsProps {
  viewModel: SchedulingDayViewModel;
}
export const DailySessions: React.FC<DailySessionsProps> = ({ viewModel }) => {
  const settings = useSettings();
  const { date, dailySessions } = viewModel;
  const { rtl: isRTL = false } = useTPAComponentsConfig();
  const totalNumberOfSessions = dailySessions.length;
  const { numberOfVisibleSessions, LoadAllSessions } = useLoadAllSessions({
    defaultValue: settingsParams.scheduleSessionLimit,
    totalNumberOfSessions,
  });

  const showDivider = (index) => {
    const lastElementIndex =
      (settings.get(settingsParams.showSessionLimit)
        ? Math.min(numberOfVisibleSessions, totalNumberOfSessions)
        : totalNumberOfSessions) - 1;
    return index !== lastElementIndex ? <Divider /> : null;
  };

  const sessionsToShow = settings.get(settingsParams.showSessionLimit)
    ? dailySessions.slice(0, numberOfVisibleSessions)
    : dailySessions;

  return (
    <li className={st(classes.root, { isRTL })} data-hook={'daily-sessions'}>
      <div className={classes.day} data-hook="day-details-wrapper">
        <DayDetails date={date} />
      </div>
      <div className={classes.sessions}>
        {sessionsToShow.map((sessionDetails, index) => {
          return (
            <li>
              <SessionDetails {...sessionDetails} />
              {showDivider(index)}
            </li>
          );
        })}
        {settings.get(settingsParams.showSessionLimit) && <LoadAllSessions />}
      </div>
    </li>
  );
};
