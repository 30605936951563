import { useLayoutEffect, useState } from 'react';
import _debounce from 'lodash.debounce';
import { useIsSSR } from './useIsSSR';

export function useSizeListener() {
  const isSSR = useIsSSR();
  const [width, setWidth] = useState((!isSSR && window.innerWidth) || 0);

  useLayoutEffect(() => {
    const resizeWindow = _debounce(() => {
      setWidth((!isSSR && window.innerWidth) || 0);
    }, 100);
    !isSSR && window.addEventListener('resize', resizeWindow);
    return () => {
      if (!isSSR) {
        return window.removeEventListener('resize', resizeWindow);
      }
    };
  });

  return width;
}
