import React from 'react';
import { st, classes } from './Body.st.css';
import { MainComponents } from '../../types';
import { RenderLocationProvider } from '../useRenderLocation';
import { SectionNotification } from 'wix-ui-tpa/SectionNotification';
import {
  AVAILABILITY_MESSAGE_TYPE,
  BodyViewModel,
} from '../../../../service-page-view-model/body-view-model/bodyViewModel';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';
import settingsParams from '../../settingsParams';
import { InjectedTranslateProps, translate } from 'yoshi-flow-editor-runtime';
import { useTPAComponentsConfig } from '../useTPAComponentsConfig';

type BodyProps = InjectedTranslateProps & {
  viewModel: BodyViewModel;
  className?: string;
};

const BodyComponent: React.FC<BodyProps> = ({
  viewModel,
  className,
  children,
  t,
}) => {
  const settings = useSettings();
  const { rtl: isRTL = false } = useTPAComponentsConfig();
  const getMessageText = (messageType: AVAILABILITY_MESSAGE_TYPE) => {
    switch (messageType) {
      case AVAILABILITY_MESSAGE_TYPE.FULLY_BOOKED:
        return (
          settings.get(settingsParams.fullCourseMessageText) ||
          t('app.widget.uou-messages.fully-booked')
        );
      case AVAILABILITY_MESSAGE_TYPE.TOO_EARLY:
        return t('app.widget.uou-messages.book-is-not-open-yet', {
          timeUntilServiceIsOpen: viewModel.timeUntilServiceIsOpen, // TODO - understand how to test it properly
        });
      case AVAILABILITY_MESSAGE_TYPE.TOO_LATE:
        return (
          settings.get(settingsParams.unBookableCourseMessageText) ||
          t('app.widget.uou-messages.book-already-closed')
        );
      case AVAILABILITY_MESSAGE_TYPE.NOT_AVAILABLE:
      default:
        return (
          settings.get(settingsParams.serviceUnavailableMessageText) ||
          t('app.widget.uou-messages.book-not-available')
        );
    }
  };
  return (
    <RenderLocationProvider value={MainComponents.BODY}>
      <section
        aria-label={t('body-aria-label.service-name-details', {
          serviceName: viewModel.serviceName,
        })}
        className={st(
          classes.root,
          {
            alignment: settings.get(settingsParams.bodyAlignment),
            columnAlignment: settings.get(settingsParams.columnPosition),
            isRTL,
          },
          className,
        )}
        data-hook="body"
      >
        <div className={classes.columnWrapper}>
          {viewModel.messageType && (
            <div
              role="status"
              data-hook="no-book-button-message"
              className={classes.notificationBanner}
            >
              <SectionNotification type="default">
                <SectionNotification.Text>
                  {getMessageText(viewModel.messageType)}
                </SectionNotification.Text>
              </SectionNotification>
            </div>
          )}
          <div data-hook="body-children-wrapper">{children}</div>
        </div>
      </section>
    </RenderLocationProvider>
  );
};

export default translate()(BodyComponent);
