import { BookingPolicyDto } from '../../types/shared-types';
import { CatalogServiceDto } from '@wix/bookings-uou-types/dist/src';

export interface BodyViewModel {
  messageType?: AVAILABILITY_MESSAGE_TYPE;
  isBookable: boolean;
  timeUntilServiceIsOpen: string;
  serviceName: string;
}

export enum AVAILABILITY_MESSAGE_TYPE {
  FULLY_BOOKED = 'FULLY_BOOKED',
  TOO_EARLY = 'TOO_EARLY',
  TOO_LATE = 'TOO_LATE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export const bodyViewModelFactory = ({
  bookingPolicyDto,
  serviceDto,
}: {
  bookingPolicyDto: BookingPolicyDto;
  serviceDto: CatalogServiceDto;
}): BodyViewModel => {
  const messageType = getAvailabilityMessageType(bookingPolicyDto);
  const isAvailableService = !messageType;
  return {
    messageType,
    isBookable: bookingPolicyDto.isBookable && isAvailableService,
    timeUntilServiceIsOpen: bookingPolicyDto.timeUntilServiceIsOpen,
    serviceName: serviceDto.info.name,
  };
};

const getAvailabilityMessageType = (
  bookingPolicyDto: BookingPolicyDto,
): AVAILABILITY_MESSAGE_TYPE | undefined => {
  if (bookingPolicyDto.isBookable) {
    if (bookingPolicyDto.isFullyBooked) {
      return AVAILABILITY_MESSAGE_TYPE.FULLY_BOOKED;
    } else if (bookingPolicyDto.isTooEarlyToBook) {
      return AVAILABILITY_MESSAGE_TYPE.TOO_EARLY;
    } else if (bookingPolicyDto.isTooLateToBook) {
      return AVAILABILITY_MESSAGE_TYPE.TOO_LATE;
    } else if (!bookingPolicyDto.isServiceAvailable) {
      return AVAILABILITY_MESSAGE_TYPE.NOT_AVAILABLE;
    }
  }
};
