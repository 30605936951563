import React, { useState } from 'react';
import { useSettings } from '@wix/tpa-settings/dist/src/contexts/SettingsContext/hooks';
import { LoadAllButton } from './LoadAllButton/LoadAllButton';
import { ISettingsParam } from '@wix/tpa-settings';

export const useLoadAllSessions = ({
  defaultValue,
  totalNumberOfSessions,
}: {
  defaultValue: ISettingsParam;
  totalNumberOfSessions: number;
}): {
  numberOfVisibleSessions: number;
  LoadAllSessions: React.FC;
} => {
  const setting = useSettings();
  const [numberOfVisibleSessions, setNumberOfSession] = useState<number>(
    setting.get(defaultValue),
  );

  const LoadAllSessions = () =>
    numberOfVisibleSessions < totalNumberOfSessions ? (
      <LoadAllButton
        onClick={() => setNumberOfSession(totalNumberOfSessions)}
      />
    ) : null;

  return { numberOfVisibleSessions, LoadAllSessions };
};
