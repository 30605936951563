import React, { useEffect } from 'react';
import {
  InjectedExperimentsProps,
  InjectedTranslateProps,
  translate,
  withExperiments,
} from 'yoshi-flow-editor-runtime';
import { IHostProps } from '@wix/native-components-infra/dist/src/types/types';
import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';
import { ServicePageViewModel } from '../../../service-page-view-model/servicePageViewModel';
import { useVisitorBILogger, WidgetBILoggerProvider } from '../bi/biContext';
import { ISection, SectionTypes, SidebarPosition } from '../types';
import { st, classes } from './Widget.st.css';
import TitleAndTagline from './TitleAndTagline';
import settingsParams from '../settingsParams';
import Description from './Description';
import Sidebar from './Sidebar/Sidebar';
import EmptyState from './EmptyState';
import Header from './Header/Header';
import Policy from './Policy/Policy';
import Details from './Details';
import Contact from './Contact';
import Body from './Body/Body';
import Scheduling from './Scheduling/Scheduling';
import { NavigationProvider } from './useNavigation';
import { SchedulingSectionViewModel } from '../../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import { TimezoneType } from '@wix/bookings-uou-types';
import UserMessage from './UserMessage/UserMessage';
import { IsSSRProvider } from './useIsSSR';

export interface ControllerProps {
  viewModel?: ServicePageViewModel;
  scheduleViewModel?: SchedulingSectionViewModel;
  changeTimezoneCallback?: (timezoneType: TimezoneType) => void;
  host: IHostProps;
  isMobile: boolean;
  isRTL: boolean;
  isSSR?: boolean;
  navigateToCalendar: () => void;
  userMessage?: {
    shouldDisplayMessage: boolean;
    closeMessage: () => void;
  };
}

type WidgetProps = InjectedExperimentsProps &
  InjectedTranslateProps &
  ControllerProps;

const getSectionComponentByType = (
  section: ISection,
  viewModel: ServicePageViewModel,
  schedule?: SchedulingSectionViewModel,
  changeTimezoneCallback?: (timezoneType: TimezoneType) => void,
) => {
  switch (section.type) {
    case SectionTypes.TITLE_TAGLINE:
      return (
        <TitleAndTagline
          key={section.type}
          section={section}
          viewModel={viewModel.titleAndTagline}
        />
      );
    case SectionTypes.SCHEDULING:
      if (viewModel.showSchedulingSection) {
        return (
          <Scheduling
            key={section.type}
            section={section}
            schedule={schedule}
            changeTimezoneCallback={changeTimezoneCallback}
          />
        );
      }
      break;
    case SectionTypes.POLICY:
      return <Policy key={section.type} viewModel={viewModel.policySection} />;
    case SectionTypes.DETAILS:
      return (
        <Details
          key={section.type}
          section={section}
          viewModel={viewModel.detailsSection}
        />
      );
    case SectionTypes.DESCRIPTION:
      return (
        <Description
          key={section.type}
          section={section}
          viewModel={viewModel.descriptionSection}
        />
      );
    case SectionTypes.CONTACT:
      return (
        <Contact
          key={section.type}
          section={section}
          viewModel={viewModel.contactSection}
        />
      );
    default:
      return null;
  }
};

export const Widget = translate()(
  withExperiments<WidgetProps>(
    ({
      viewModel,
      scheduleViewModel,
      changeTimezoneCallback,
      isMobile,
      isRTL,
      navigateToCalendar,
      userMessage,
      isSSR = false,
      t,
    }) => {
      const settings = useSettings();
      const biLogger = useVisitorBILogger();
      const getSectionByType = (sectionType: SectionTypes): ISection => {
        return settings
          .get(settingsParams.sections)
          .find(({ type }) => type === sectionType) as ISection;
      };

      const isSectionVisible = (section: ISection): boolean => {
        return (
          section.visible &&
          (!settings.get(settingsParams.sidebarVisibility) ||
            section.type !== settings.get(settingsParams.sidebarSection))
        );
      };

      const shouldShowOnlyImage =
        settings.get(settingsParams.headerImageVisibility) &&
        !settings.get(settingsParams.headerBookButtonVisibility) &&
        !settings.get(settingsParams.headerTitleVisibility);
      const isServiceWithoutImage = !viewModel?.header?.imageUri;
      const shouldShowHeader =
        settings.get(settingsParams.headerVisibility) &&
        !(shouldShowOnlyImage && isServiceWithoutImage);

      const renderBody = (widgetViewModel) => (
        <Body viewModel={widgetViewModel.body}>
          {settings
            .get(settingsParams.sections)
            .map((section) =>
              isSectionVisible(section)
                ? getSectionComponentByType(
                    section,
                    widgetViewModel,
                    scheduleViewModel,
                    changeTimezoneCallback,
                  )
                : null,
            )}
        </Body>
      );

      const renderSideBar = (widgetViewModel) =>
        settings.get(settingsParams.sidebarVisibility) ? (
          <Sidebar sectionType={settings.get(settingsParams.sidebarSection)}>
            {getSectionComponentByType(
              getSectionByType(settings.get(settingsParams.sidebarSection)),
              widgetViewModel,
              scheduleViewModel,
              changeTimezoneCallback,
            )}
          </Sidebar>
        ) : null;

      useEffect(() => {
        biLogger.bookingsServicesPageView({
          emptyState: !viewModel,
          message_to_user: viewModel?.body.messageType,
          platform: isMobile ? 'mobile' : '',
          containers: viewModel
            ? `body${shouldShowHeader ? ',header' : ''}${
                settings.get(settingsParams.sidebarVisibility) ? ',sidebar' : ''
              }`
            : '',
          sections: viewModel
            ? JSON.stringify(settings.get(settingsParams.sections))
            : '',
        });
      });

      return (
        <TPAComponentsProvider value={{ mobile: isMobile, rtl: isRTL }}>
          <IsSSRProvider value={isSSR}>
            <NavigationProvider value={{ navigateToCalendar }}>
              <div
                className={st(classes.root, {
                  sidebarPosition: settings.get(settingsParams.sidebarPosition),
                  isMobile,
                  isRTL,
                })}
              >
                {viewModel ? (
                  <>
                    <UserMessage
                      isOpen={userMessage?.shouldDisplayMessage || false}
                      onRequestClose={userMessage?.closeMessage || (() => {})}
                      isMobile={isMobile}
                      message={t('app.widget.uou-messages.non.premium.message')}
                      okLabel={t('app.widget.uou-messages.non.premium.approve')}
                    />
                    <div
                      className={classes.root}
                      data-hook="booking-service-page-wrapper"
                    >
                      {shouldShowHeader && (
                        <Header viewModel={viewModel.header} />
                      )}
                      <div className={classes.dynamicWrapper}>
                        {settings.get(settingsParams.sidebarPosition) ===
                        (isRTL
                          ? SidebarPosition.RIGHT
                          : SidebarPosition.LEFT) ? (
                          <>
                            {renderSideBar(viewModel)}
                            {renderBody(viewModel)}
                          </>
                        ) : (
                          <>
                            {renderBody(viewModel)}
                            {renderSideBar(viewModel)}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <EmptyState />
                )}
              </div>
            </NavigationProvider>
          </IsSSRProvider>
        </TPAComponentsProvider>
      );
    },
  ),
);

export default (props) => (
  <WidgetBILoggerProvider>
    <Widget {...props} />
  </WidgetBILoggerProvider>
);
