import React from 'react';
import { SectionTypes, AlignmentOptions } from '../../types';
import BookButton from '../BookButton/BookButton';
import { st, classes } from './Section.st.css';
import { Divider } from 'wix-ui-tpa/Divider';
import { useGlobalAlignment } from '../useGlobalAlignment';

interface SectionProps {
  withBookButton?: boolean;
  sectionType: SectionTypes;
  buttonAlignment?: AlignmentOptions;
  childrenAlignment?: AlignmentOptions;
  className?: string;
}

const Section: React.FC<SectionProps> = ({
  children,
  sectionType,
  withBookButton,
  className,
  buttonAlignment,
  childrenAlignment,
  ...rest
}) => {
  const globalAlignment = useGlobalAlignment();

  return (
    <>
      <div
        className={st(classes.root, {
          withBookButton: !!withBookButton,
          alignment: childrenAlignment || globalAlignment,
        })}
        {...rest}
        data-section-type={sectionType}
      >
        {sectionType !== SectionTypes.DETAILS && (
          <Divider data-hook="section-divider" className={classes.divider} />
        )}
        {children && (
          <div
            data-hook={`children-${sectionType}`}
            className={st(classes.childrenWrapper, {}, className)}
          >
            {children}
          </div>
        )}
        {withBookButton && (
          <div
            data-hook="book-button-wrapper"
            className={st(
              classes.buttonWrapper,
              { alignment: buttonAlignment || globalAlignment },
              className,
            )}
          >
            <BookButton
              dataHook={`book-button-${sectionType}`}
              renderedAt={sectionType}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Section;
