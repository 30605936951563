import React from 'react';
import {
  InjectedExperimentsProps,
  InjectedTranslateProps,
  translate,
  withExperiments,
} from 'yoshi-flow-editor-runtime';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { st, classes } from './TitleAndTagline.st.css';
import { ISection, MainComponents, SectionTypes } from '../../types';
import Section from '../Section';
import { TitleAndTaglineViewModel } from '../../../../service-page-view-model/title-and-tagline-view-model/titleAndTaglineViewModel';
import { Badge, BADGE_PRIORITY } from 'wix-ui-tpa/Badge';
import { Camera } from 'wix-ui-tpa/dist/src/assets/icons/index';
import { useRenderLocation } from '../useRenderLocation';
import { useGlobalAlignment } from '../useGlobalAlignment';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';
import settingsParams from '../../settingsParams';
import { useTPAComponentsConfig } from '../useTPAComponentsConfig';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';

type TitleAndTaglineProps = InjectedExperimentsProps &
  InjectedTranslateProps & {
    viewModel?: TitleAndTaglineViewModel;
    section: ISection;
    className?: string;
  };

export default translate()(
  withExperiments<TitleAndTaglineProps>(({ t, viewModel, className }) => {
    const location = useRenderLocation();
    const {
      mobile: isMobile = false,
      rtl: isRTL = false,
    } = useTPAComponentsConfig();
    const settings = useSettings();
    const showOnlineBadge =
      settings.get(settingsParams.onlineBadgeVisibility) &&
      viewModel?.onlineProvider;
    const showServiceTitle = settings.get(
      settingsParams.serviceTitleVisibility,
    );
    const showServiceTagline =
      settings.get(settingsParams.serviceTaglineVisibility) &&
      viewModel?.tagline;

    const content: JSX.Element[] = [];
    if (showOnlineBadge) {
      content.push(
        <Badge
          key="title-and-tagline-badge"
          icon={<Camera />}
          data-hook="title-and-tagline-badge"
          priority={BADGE_PRIORITY.light}
          className={classes.badge}
        >
          {settings.get(settingsParams.onlineBadgeText) ||
            t('app.settings.sections.titles.default.online-badge.text')}
        </Badge>,
      );
    }
    if (showServiceTitle) {
      const titleHtmlTag =
        location === MainComponents.BODY
          ? settings.get(settingsParams.bodyServiceTitleHtmlTag)
          : settings.get(settingsParams.sidebarServiceTitleHtmlTag);
      const shouldHideTitleFromScreenReader =
        settings.get(settingsParams.headerTitleVisibility) &&
        settings.get(settingsParams.headerTitleHtmlTag) ===
          AccessibilityHtmlTags.MainHeader &&
        titleHtmlTag === AccessibilityHtmlTags.MainHeader;
      content.push(
        <div
          data-hook="title-and-tagline-title-wrapper"
          aria-hidden={shouldHideTitleFromScreenReader}
          key="title-and-tagline-title-wrapper"
        >
          <Text
            data-hook="title-and-tagline-title"
            className={classes.title}
            typography={TYPOGRAPHY.largeTitle}
            tagName={titleHtmlTag}
          >
            {viewModel?.title}
          </Text>
        </div>,
      );
    }
    if (showServiceTagline) {
      content.push(
        <Text
          key="title-and-tagline-tagline"
          data-hook="title-and-tagline-tagline"
          className={classes.tagline}
          typography={TYPOGRAPHY.runningText}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {viewModel?.tagline}
        </Text>,
      );
    }

    return content.length ? (
      <Section
        sectionType={SectionTypes.TITLE_TAGLINE}
        childrenAlignment={settings.get(
          settingsParams.titleAndTaglineAlignment,
        )}
      >
        <div
          className={st(
            classes.root,
            {
              alignment:
                settings.get(settingsParams.titleAndTaglineAlignment) ||
                useGlobalAlignment(),
              location,
              isMobile,
              isRTL,
            },
            className,
          )}
          data-hook="title-and-tagline-wrapper"
        >
          {content}
        </div>
      </Section>
    ) : null;
  }),
);
